const Stack = () => {
  return (
    <div id="stack">
      <div className="flex">
        <div className="text-2xl font-bold">
          <h1>Stack</h1>
        </div>
      </div>
      <div className="flex">
        <div className="text-lg">
          <p className="py-2">
            I have experience with the following languages, frameworks and
            tools:
          </p>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-left text-sm font-light">
                <thead className="border-b font-medium dark:border-neutral-500">
                  <tr>
                    <th scope="col" className="px-6 py-4">
                      Language
                    </th>
                    <th scope="col" className="px-6 py-4">
                      Frameworks/Libraries
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="hover:bg-gray-700 hover:text-white transition duration-250 ease-in-out">
                    <td className="whitespace-nowrap px-6 py-4">Python</td>
                    <td className="whitespace-nowrap px-6 py-4">
                      Flask, NumPy, SciPy, Pandas
                    </td>
                  </tr>
                  <tr className="hover:bg-gray-700 hover:text-white transition duration-250 ease-in-out">
                    <td className="whitespace-nowrap px-6 py-4">Go</td>
                    <td className="whitespace-nowrap px-6 py-4">
                      Docker, Gin, Gorm
                    </td>
                  </tr>
                  <tr className="hover:bg-gray-700 hover:text-white transition duration-250 ease-in-out">
                    <td className="whitespace-nowrap px-6 py-4">Typescript</td>
                    <td className="whitespace-nowrap px-6 py-4">React</td>
                  </tr>
                  <tr className="hover:bg-gray-700 hover:text-white transition duration-250 ease-in-out">
                    <td className="whitespace-nowrap px-6 py-4">SQL</td>
                    <td className="whitespace-nowrap px-6 py-4">PostgreSQL</td>
                  </tr>
                  <tr className="hover:bg-gray-700 hover:text-white transition duration-250 ease-in-out">
                    <td className="whitespace-nowrap px-6 py-4">
                      Rust
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">-</td>
                  </tr>
                  <tr className="hover:bg-gray-700 hover:text-white transition duration-250 ease-in-out">
                    <td className="whitespace-nowrap px-6 py-4">
                      Shell Script
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stack;
