const Contact = () => {
  return (
    <div id="contact">
      <div className="flex">
        <div className="text-2xl font-bold">
          <h1>Get in touch!</h1>
        </div>
      </div>
      <div className="flex">
        <div className="text-lg">
          <p className="py-2">
            You can reach me at{" "}
            <a className="underline" href="mailto:rvhonorato@protonmail.com">
              rvhonorato@protonmail.com
            </a>{" "}
            (full cv available upon request)
          </p>
          <p className="py-2">
            I'm also available for <b>free consultations</b>!
          </p>
          <p className="py-2">
            Send me an e-mail if you need assistance to build a webserver for
            your scientific project, a website for your non-profit organization,
            advice on how to use a specific tool or programming language for
            your research or anything else related to my expertise.
          </p>
          <p className="py-2">(:</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
