import photo001 from "./IMG_0001.webp";
import photo002 from "./IMG_0002.webp";
import photo003 from "./IMG_0003.webp";
import photo004 from "./IMG_0004.webp";
import photo005 from "./IMG_0005.webp";
import photo006 from "./IMG_0006.webp";
import photo007 from "./IMG_0007.webp";
import photo008 from "./IMG_0008.webp";
import photo009 from "./IMG_0009.webp";
import photo010 from "./IMG_0010.webp";
import photo011 from "./IMG_0011.webp";
import photo012 from "./IMG_0012.webp";
import photo013 from "./IMG_0013.webp";
import photo014 from "./IMG_0014.webp";
import photo015 from "./IMG_0015.webp";
import photo016 from "./IMG_0016.webp";
import photo017 from "./IMG_0017.webp";

export const photos = [
  {
    src: photo001,
    width: 3,
    height: 5,
    date: "2019-01-01",
  },
  {
    src: photo002,
    width: 3,
    height: 5,
    date: "2019-01-01",
  },
  {
    src: photo003,
    width: 1,
    height: 1,
    date: "2019-01-01",
  },
  {
    src: photo004,
    width: 3,
    height: 4,
    date: "2019-01-01",
  },
  {
    src: photo005,
    width: 4,
    height: 3,
    date: "2019-01-01",
  },
  {
    src: photo006,
    width: 4,
    height: 3,
    date: "2019-01-01",
  },
  {
    src: photo007,
    width: 4,
    height: 3,
    date: "2019-01-01",
  },
  {
    src: photo008,
    width: 4,
    height: 3,
    date: "2019-01-01",
  },
  {
    src: photo009,
    width: 3,
    height: 5,
    date: "2019-01-01",
  },
  {
    src: photo010,
    width: 4,
    height: 3,
    date: "2019-01-01",
  },
  {
    src: photo011,
    width: 4,
    height: 3,
    date: "2019-01-01",
  },
  {
    src: photo012,
    width: 4,
    height: 3,
    date: "2019-01-01",
  },
  {
    src: photo013,
    width: 4,
    height: 3,
    date: "2019-01-01",
  },
  {
    src: photo014,
    width: 4,
    height: 3,
    date: "2019-01-01",
  },
  {
    src: photo015,
    width: 4,
    height: 3,
    date: "2019-01-01",
  },
  {
    src: photo016,
    width: 3,
    height: 5,
    date: "2019-01-01",
  },
  {
    src: photo017,
    width: 3,
    height: 5,
    date: "2019-01-01",
  },
];
