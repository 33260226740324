import Footer from "../components/Footer";
import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
// import photo01 from "../assets/photo001.webp";
import { photos } from "../assets/photos";
import SelectedImage from "../components/SelectedImage";

const Life = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  // @ts-ignore
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const imageRenderer = useCallback(
    // @ts-ignore
    ({ index, left, top, key, photo }) => (
      // @ts-ignore
      <SelectedImage
        selected={false}
        key={key}
        margin={"2px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
      />
    ),
    []
  );

  // Randomize the order of the photos
  photos.sort(() => Math.random() - 0.5);

  return (
    <>
      <div className="py-4 px-2 flex items-center justify-center ">
        <p className="text-sm text-gray-400">
          Photos © 2023 by Rodrigo Vargas Honorato licensed under{" "}
          <a
            className="underline"
            href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CC BY-NC-ND 4.0
          </a>
        </p>
      </div>

      {/* @ts-ignore */}
      <Gallery
        photos={photos}
        // @ts-ignore
        renderImage={imageRenderer}
        margin={5}
        onClick={openLightbox}
      />
      {/* @ts-ignore */}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              // @ts-ignore
              views={photos.map((x) => ({
                ...x,
                // @ts-ignore
                srcset: x.srcSet,
                // @ts-ignore
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      {/* </div> */}
      <div className="py-8">
        <Footer />
      </div>
    </>
  );
};

export default Life;
