// import React from "react";
import { Link } from "react-router-dom";
import { CameraIcon, TerminalIcon } from "../components/Icons";
import Footer from "../components/Footer";

const LandingPage = () => {
  let CameraMsg = "Everything else";
  let TerminalMsg = "Work related stuff";

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow">
        <div className="flex h-screen">
          <div className="w-1/2 flex items-center justify-center">
            <div className="flex flex-col items-center">
              <Link to="/work">
                <TerminalIcon message={TerminalMsg} />
              </Link>
            </div>
          </div>
          <div className="w-1/2 flex items-center justify-center border-l border-grey">
            <div className="flex flex-col items-center">
              <Link to="/life">
                <CameraIcon message={CameraMsg} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
