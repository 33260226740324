import Sidebar from "../components/Sidebar";
import Content from "../components/Content";
import Footer from "../components/Footer";

const Work = () => {
  return (
    <div className="flex">
      <aside className="h-screen sticky top-0 hidden md:block">
        <Sidebar />
      </aside>
      <main>
        <Content />
      </main>
      <Footer />
    </div>
  );
};

export default Work;
