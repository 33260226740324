const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div
      className="bg-gray-100 text-gray-400 text-center py-2 text-sm w-full border-t border-grey"
      style={{ position: "fixed", bottom: 0 }}
    >
      &copy; {currentYear} Designed by Rodrigo V Honorato. All rights reserved.
    </div>
  );
};

export default Footer;
