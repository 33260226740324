import { Link } from "react-router-dom";

const Bio = () => {
  return (
    <div id="bio">
      <div className="block md:hidden">
        <div className="flex flex-col items-center text-center">
          <div className="">
            <img
              src={process.env.PUBLIC_URL + "/rodrigo.webp"}
              alt="Avatar"
              className="rounded-full h-32 w-32 object-cover mx-auto"
            />
            <div className="py-2 font-bold">
              <p>Rodrigo V Honorato</p>
            </div>
            <div className="py-2">
              <a
                href="http://github.com/rvhonorato"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="px-2 fa-brands fa-github hover:text-red-500"></i>
              </a>
              <a
                href="https://scholar.google.com/citations?user=WVneCBYAAAAJ&hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="px-2 fa-solid fa-graduation-cap hover:text-red-500"></i>
              </a>
            </div>
          </div>
        </div>
        <hr className="my-4" />
      </div>

      <div className="flex">
        <div className="text-2xl font-bold">
          <h1>Hi!</h1>
        </div>
      </div>
      <div className="flex">
        <div className="text-lg">
          <p className="py-2">
            I am a Researcher / Technologist hybrid with a PhD in Bioinformatics
            and extensive experience in the development of computational tools
            for the analysis of biological data as well as full-stack web
            development and baremetal high-performance computing (HPC) setup and
            operation.
          </p>
          <p className="py-2">
            Currently working as the <b>Software Development and Operations</b>{" "}
            specialist in the{" "}
            <a
              href="http://uu.nl"
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Utrecht University
            </a>{" "}
            at the{" "}
            <a
              href="http://bonvinlab.org"
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              BonvinLab
            </a>
            . There I am responsible for the development and maintenance
            (devops+fullstack) of the{" "}
            <a
              className="underline"
              href="https://wenmr.science.uu.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              EOSC-WeNMR portal
            </a>
            , a web-based platform for the analysis of biomolecular data with
            over{" "}
            <a
              className="underline"
              href="https://wenmr.science.uu.nl/stats"
              target="_blank"
              rel="noopener noreferrer"
            >
              33.000 registered users
            </a>{" "}
            and also actively involved in the development of multiple Research
            Software, see my{" "}
            <a
              className="underline"
              href="https://github.com/rvhonorato"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub profile
            </a>{" "}
            for more details.
          </p>
          <p className="py-2">
            On my free time I volunteer at the{" "}
            <a
              className="underline"
              href="https://www.coloredqollective.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Colored Qollective
            </a>
            , go to the gym, play video games, spoil my cats and{" "}
            <Link className="underline" to="/life">
              take pictures
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Bio;
