import Bio from "./Bio";
import Skills from "./Stack";
import Interests from "./Interests";
import Publications from "./Publications";
import Contact from "./Contact";

const Content = () => {
  return (
    <div
      id="content"
      className="flex flex-col min-h-screen py-8 md:py-20 px-2 md:px-16"
    >
      <Bio />
      <hr className="my-4" />
      <Skills />
      <hr className="my-4" />
      <Interests />
      <hr className="my-4" />
      <Publications />
      <hr className="my-4" />
      <Contact />
    </div>
  );
};

export default Content;
