// import Divider from "./Divider";

const Sidebar = () => {
  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="bg-gray-100 min-h-screen w-64 flex justify-center items-center border-r border-black">
      <div className="flex flex-col items-center text-center">
        <div className="py-4">
          <img
            src={process.env.PUBLIC_URL + "/rodrigo.webp"}
            alt="Avatar"
            className="rounded-full h-32 w-32 object-cover mx-auto"
          />
          <div className="py-2 font-bold">
            <p>Rodrigo V Honorato</p>
          </div>
          <div className="py-2">
            <a
              href="http://github.com/rvhonorato"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="px-2 fa-brands fa-github hover:text-red-500"></i>
            </a>
            <a
              href="https://scholar.google.com/citations?user=WVneCBYAAAAJ&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="px-2 fa-solid fa-graduation-cap hover:text-red-500"></i>
            </a>
          </div>
        </div>
        <ul>
          <li
            className="py-4 px-16 block hover:bg-gray-700 hover:text-white transition duration-250 ease-in-out"
            style={{ cursor: "pointer" }}
          >
            <p onClick={() => scrollTo("bio")}>Bio</p>
          </li>
          <li
            className="py-4 px-16 block hover:bg-gray-700 hover:text-white transition duration-250 ease-in-out"
            style={{ cursor: "pointer" }}
          >
            <p onClick={() => scrollTo("stack")}>Stack</p>
          </li>
          <li
            className="py-4 px-16 block hover:bg-gray-700 hover:text-white transition duration-250 ease-in-out"
            style={{ cursor: "pointer" }}
          >
            <p onClick={() => scrollTo("interests")}>Interests</p>
          </li>
          <li
            className="py-4 px-16 block hover:bg-gray-700 hover:text-white transition duration-250 ease-in-out"
            style={{ cursor: "pointer" }}
          >
            <p onClick={() => scrollTo("publications")}>Publications</p>
          </li>
          <li
            className="py-4 px-16 block hover:bg-gray-700 hover:text-white transition duration-250 ease-in-out"
            style={{ cursor: "pointer" }}
          >
            <p onClick={() => scrollTo("contact")}>Contact</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
