const Publications = () => {
  return (
    <div id="publications">
      <div className="flex">
        <div className="text-2xl font-bold">
          <h1>Publications</h1>
        </div>
      </div>
      <div className="flex">
        <div className="text-lg">
          <p>
            You can see the full list of academic publications in my{" "}
            <a
              className="underline"
              href="https://scholar.google.com/citations?user=WVneCBYAAAAJ&hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Scholar
            </a>{" "}
            profile or at my{" "}
            <a
              className="underline"
              href="https://orcid.org/0000-0001-5267-3002"
              target="_blank"
              rel="noopener noreferrer"
            >
              ORCID
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Publications;
