const Interests = () => {
  let startDate = new Date("2006-03-17");
  let currentDate = new Date();
  let years = currentDate.getFullYear() - startDate.getFullYear();
  return (
    <div id="interests">
      <div className="flex">
        <div className="text-2xl font-bold">
          <h1>Interests</h1>
        </div>
      </div>
      <div className="flex">
        <div className="text-lg">
          <p className="py-2">
            Coming from an academic background in Biology, I have always been
            deeply interested in "how life works", this led me to the area of
            Structural Biology and Bioinformatics, where I have been working for
            the last {years} years.
          </p>
          <p className="py-2">
            Most of my research has been focused on the development of
            computational tools for the analysis of biological data, and,
            because of that, software development as well as the setup of
            High-Performance computing clusters have always been a big part of my
            work.
          </p>
          <p className="py-2">
            More recently I have been working on the development of web-based
            platforms for the analysis of biomolecular data, which has led me to
            learn about full-stack web development and devops.
          </p>
          <p className="py-2">
            The current technologies/practices I'm most interested are:
            <ul className="list-disc list-inside">
              <li>Rust + WebAssembly</li>
              <li>Mini/Microservices</li>
              <li>Free (Zero-Clause) access to Research Software</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Interests;
